.user-form {
  .ant-btn-default {
    background-color: $primary;
    color: #ffffff;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  .ant-form-item .ant-form-item-label > label {
    font-size: 16px;
    font-weight: 700;
    color: $primary;
  }
  .form-content {
    background-color: $white;
    padding: 40px 20px;
  }

  .text-right {
    text-align: right;
  }
}

.add-user-form {
  .ant-row {
    margin-right: 0 !important;
    margin-left: 0 !important ;
    border: none;
    .edit-user-form{
      .ant-card-body{
        padding: 50px 24%;
      }
      .ant-card-bordered{
        border: none;
      }
    }
  }

  .ant-col-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .form-label {
    background-color: $primary;
    color: $white;
    padding: 15px !important;
  }
  // background-color: $white;
  .save-btn-wrapper {
    padding-bottom: 0;
    margin-bottom: 0;
    .save-multiple-btn {
      .ant-btn {
        height: 48px;
        }
    }
    .save-btn {
      padding: 0 50px;
      height: 48px;
    }
    .btn-delete{
      float: right;
    }
    .csv-btn{
      margin-top: 5px;
      padding:0 10px;
      height: 48px;
      background-color: $primary;
      color: white;
      border: none;
      width: 140px;
       span{
        font-size: 16px;
       }
       &:hover{
        background-color: #70558a;
       }
    }
    text-align: center;
  }
  .ant-btn.ant-btn-lg{
    text-align: center;
  }
  .right-side {
    background-color: $white;
    border-left: 1px solid #ccc;
    height: 100%;

  }
  .right-side-content {
    height: calc(100% - 80px);
    .icon-import-wrapper {
      :hover {
        cursor: pointer;
      }
    }
  }
  .upload {
    .anticon-eye {
      display: none;
    }
    .text-upload {
      color: $primary;
      font-weight: 700;
    }
  }

  .btn-choose-file {
    right: 0px;
    top: 70px;
  }
  .ant-form{
    .text-placeholder{
      ::placeholder {
        color: black;
      }
    }
  }
}
