.card-wrapper-department {
  margin-left: 5.325%;
    .ant-card-head{
      padding: 0;
      width: 100%;
   .title-header{
    height: 60px;
    font-size: 24px;
    padding: 0 20px;
     .title{
      font-size: 24px;
      font-weight: bold;
     }
   }
    }
    .extra-card {
      height: 50px;
      padding:0 20px;
      margin: 0;
      border-top:1px solid;
      .extra-search {
        flex: 1.5;
        .span{
          display: flex;
          justify-content: center;
        }
        .ant-input-group-addon .ant-btn{
          background-color: white;
          border-left:none ;
          &:hover{
            border: 1px solid #535353
            ;
          }
        }
      }
      .extra-select {
        .ant-select .ant-select-clear {
          right: 20% !important;
        }
        .ant-select-selector {
          width: 200px !important;
        }
        flex: 1;
      }
      .extra-tree-select {
        .ant-select .ant-select-clear {
          right: 40px !important;
        }
        flex: 1;
      }
    }
    .table-input-search {
      width: 400px;
    }
  
    .ant-card .ant-card-extra {
      width: 100%;
    }
   
    .card-wrapper .extra-card{
      z-index: 1;
     }
  
    .ant-card .ant-card-body{
      padding: 0 ; 
      z-index: -1;
    }
  
    .custom-scrollbar {
      height: 600px;
      overflow-y: scroll;
      .ant-table-bordered .ant-table-containert .table-layout{
        background-color: #535353;
      }
    }
    .custom-scrollbar::-webkit-scrollbar {
      width: 20px;
      background-color: #f5f5f5;
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: $primary;
    }
    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background-color: $primary;
    }
    .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container{
      border-inline-start:0 solid #f0f0f0;
      border-top: 0 solid #BBBBBB;
      border-right: 1px solid #BBBBBB;
      border-bottom: 1px solid #BBBBBB;
    }
    .ant-table-wrapper .ant-table-tbody > tr> td{
        border:none;
        // background-color: red;
    }
    .table-custom {
      width: 100%;
      padding:0;
      margin: 0;
      .table-row-light {
        background-color: inherit; /* light gray */
        .ant-table-cell{
           left: 0;
            right: 0;
         }
       }
  
      .table-row-dark {
        background-color: #e8e8e8; /* slightly darker gray */
      }
      .ant-table-thead >tr>th{
        background-color: #F2F2F2;
        border:1px solid #BBBBBB;
        border-bottom: none;
        border-top: none;
        border-right: none;
        // border-left: none;

      }
      .ant-table-thead>tr>td:first-child {
        border: none;
        border-left: none;
      }
      .ant-table-thead>tr{
        border: none;
        border-left: none;
        line-height: 11px;
        width: 40px;
      }
      .ant-table-tbody>tr>td:first-child{
        padding:0 16px 0 16px;
      }
      .ant-table-tbody>tr>td{
        margin: 0;
        border:1px solid  #BBBBBB;
        border-bottom: none;
        border-right: none !important;
      }
      .ant-table-tbody >tr{
        .ant-table-row-expand-icon-cell{
          padding: 8px;
        }
      }
      .ant-table-tbody>tr{
        border:none;
        line-height: 35px;
        width: 40px;
      }
      .ant-table-tbody>tr>:first-child {
      //  border-left: none;
       border-bottom: none;
      }
      .ant-table-tbody >tr>td{
        .ant-table-row-expand-icon-cell{
         border-left: none;
        }
      }
      .ant-table-tbody>tr>:last-child {
        //  border-left: none;
         border-bottom: none;
         border-right: none;
        }

    }
    .table-custom-layout {
      width: 100%;
      padding:0;
      margin: 0;
      .table-row-light {
        background-color: inherit; /* light gray */
        .ant-table-cell{
           left: 0;
            right: 0;
         }
       }
  
      .table-row-dark {
        background-color: #e8e8e8; /* slightly darker gray */
      }
      .ant-table-thead >tr>th{
        background-color: #F2F2F2;
        border:1px solid #BBBBBB;
        border-bottom: none;
        border-top: none;
        border-right: none;
        // border-left: none;

      }
      .ant-table-thead>tr>td:first-child {
        border: none;
        border-left: none;
      }
      .ant-table-thead>tr{
        border: none;
        border-left: none;
        line-height: 32px;
        width: 40px;
      }
      .ant-table-tbody>tr>td:first-child{
        padding:0 16px 0 16px;
      }
      .ant-table-tbody>tr>td{
        margin: 0;
        border:1px solid  #BBBBBB;
        border-bottom: none;
        border-right: none !important;
      }
      .ant-table-tbody >tr{
        .ant-table-row-expand-icon-cell{
          padding: 8px;
        }
      }
      .ant-table-tbody>tr{
        border:none;
        line-height: 35px;
        width: 40px;
      }
      .ant-table-tbody>tr>:first-child {
      //  border-left: none;
       border-bottom: none;
      }
      .ant-table-tbody >tr>td{
        .ant-table-row-expand-icon-cell{
         border-left: none;
        }
      }
      .ant-table-tbody>tr>:last-child {
        //  border-left: none;
         border-bottom: none;
         border-right: none;
        }

    }
    .label-tree-select {
      color: $primary;
      font-size: 16px;
      font-weight: 600;
    }
    .ant-table-cell{
      .ant-switch-checked .ant-switch-inner{
        background-color: #87EB6E;
      }
      .ant-switch{
        background-color: #F2F2F2;
      }
      .ant-switch .ant-switch-handle::before{
        background-color: #BBBBBB;
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .card-wrapper-department {
   margin-left: 3.8em;
    }
  }
  @media only screen and (max-width: 1460px) {
    .uncollapsed{
      .card-wrapper-department {
         margin-left: 3.8em;
      }
    }
  }