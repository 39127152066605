.layout-header {
  padding: 0;
  background: #ffffff !important;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  position: sticky;
  top: 0;
  z-index: 2;

  .row-top-header{
    height: 64px;
    background-color: #422a61 ;
    .button-logout{
      border-radius: 50%;
      height: 2em;
      width: 2em;
    }
  }
  .ant-segmented {
    width: 100%;
    padding: 0;
  }
  .ant-segmented
    .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(
      .ant-segmented-item-disabled
    ) {
    color: #ffffff;
  }
  .ant-segmented .ant-segmented-item {
    border: none;
    background: #291c3d;
    font-weight: 600;
    color: #ffffff;
  }
  .ant-segmented .ant-segmented-item-selected {
    background-color: #523574;
    color: #ffffff;
    font-weight: 600;
  }
  .ant-segmented.ant-segmented-lg .ant-segmented-item-label{
    font-size: 20px;
  }
  .avatar {
    height: 35px;
    width: 35px;
    border-radius: 100rem;
    background: #f7f7f7;
    margin-right: 10px;
  }
  .dropdown-menu {
    .display-name {
      margin-right: 10px;
    }
  }
  .ant-dropdown-menu {
    border-radius: 4px !important;
  }
  .ant-btn {
    height: 28px;
    padding: 2px 8px;
  }
}
