.form-fabs {
  .ant-btn-default {
    background-color: $primary;
    color: #ffffff;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  .ant-form-item .ant-form-item-label > label {
    font-size: 16px;
    font-weight: 700;
    color: $primary;
  }
  .ant-form-item{
    margin-bottom: 0;
  }
  .btn-submit{
    .ant-btn{
        width: 140px;
        height: 40px;
    }
    .btn-delete{
      float: right;
    }
    text-align: center;
 }
}

