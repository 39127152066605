.header-table {
  width: 100%;
  color: black;
  height: 60px;
  background: white;
  padding: 0 16px;
    .ant-row{
      font-size: 24px;
    }
}

.card-wrapper {
  .ant-card-head{
    padding: 0;
    border: 1px solid $primary;
    width: 100%;
    border-top: 1px solid $primary;
 .title-header{
  height: 60px;
  font-size: 24px;
  padding: 0 20px;
   .title{
    font-size: 24px;
    font-weight: bold;
   }
 }
  }
  .extra-card {
    height: 50px;
    padding:0 20px;
    margin: 0;
    border-top:1px solid;
    .extra-search {
      flex: 1.5;
      .span{
        display: flex;
        justify-content: center;
      }
      .ant-input-group-addon .ant-btn{
        background-color: white;
        border-left:none ;
        &:hover{
          border: 1px solid #535353
          ;
        }
      }
    }
    .extra-select {
      .ant-select .ant-select-clear {
        right: 20% !important;
      }
      .ant-select-selector {
        width: 200px !important;
      }
      flex: 1;
    }
    .extra-tree-select {
      .ant-select .ant-select-clear {
        right: 40px !important;
      }
      flex: 1;
    }
  }
  .table-input-search {
    width: 400px;
  }

  .ant-card .ant-card-extra {
    width: 100%;
  }
 
  .card-wrapper .extra-card{
    z-index: 1;
   }

  .ant-card .ant-card-body{
    padding: 0 ; 
    z-index: -1;
  }

  .custom-scrollbar {
    height: 600px;
    overflow-y: scroll;
    .ant-table-bordered .ant-table-containert .table-layout{
      background-color: #535353;
    }
  }
  .custom-scrollbar::-webkit-scrollbar {
    width: 20px;
    background-color: #f5f5f5;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: $primary;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: $primary;
  }
  .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container{
    border-inline-start:0 solid #f0f0f0;
    border-top: 1px solid $primary;
    border-right: 1px solid #BBBBBB;
    border-bottom: 1px solid #BBBBBB;
  }

  .table-custom {
    width: 100%;
    padding:0;
    margin: 0;
    .table-row-light {
      background-color: inherit; /* light gray */
      .ant-table-cell{
         left: 0;
          right: 0;
       }
     }

    .table-row-dark {
      background-color: #e8e8e8; /* slightly darker gray */
    }
    .table-row {
      background-color: red;
    }
    .ant-table-thead >tr>th{
      background-color: #F2F2F2;
      border:1px solid #BBBBBB;
      border-bottom: none;
    }
    .ant-table-thead >tr{
      line-height: 31px;
    }
    .ant-table-tbody>tr>td{
      margin: 0;
      border:1px solid  #BBBBBB;
      border-bottom: none;
    }
    .ant-table-tbody>tr>td:first-child{
      padding:0 10px 0 10px;
    }
    .ant-table-tbody{
      .ant-table-expanded-row>td{
        // border-left: 1px solid #EEEBF2;
      }
    }
    .ant-table-tbody>tr{
      line-height: 35px;
    }
    // .ant-table-thead>tr>th:first-child{
      
    //   padding:0 10px 0 10px;
    // }
  }
  .label-tree-select {
    color: $primary;
    font-size: 16px;
    font-weight: 600;
  }
  .ant-table-cell{
    .ant-switch-checked .ant-switch-inner{
      background-color: #87EB6E;
    }
    .ant-switch{
      background-color: #F2F2F2;
    }
    .ant-switch .ant-switch-handle::before{
      background-color: #BBBBBB;
    }
  }
  .ant-table-content{
    .ant-table-expanded-row{
      padding: 0;
    }
  }
}
