@import './variables.scss';

// reset style
#root {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

ol,
ul {
  list-style: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans JP', sans-serif;
}

// margin and padding
// Adjust this to include the pixel amounts you need.
$spaceamounts: (
  0,
  3,
  5,
  8,
  10,
  13,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  70,
  75,
  100
);
$sides: (top, bottom, left, right);
// example: mt-5, pb-50
@each $space in $spaceamounts {
  .ma-#{$space} {
    margin: #{$space}px !important;
  }
  .pa-#{$space} {
    padding: #{$space}px !important;
  }
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
// font-weight
// example: fw-100, fw-900
@for $i from 1 through 9 {
  .fw-#{$i * 100} {
    font-weight: ($i * 100) !important;
  }
}
// font-size
// example: fz-9 to fz-40
@for $i from 9 through 40 {
  .fz-#{$i} {
    font-size: #{$i}px !important;
  }

  // down font-size for screen
  // @media only screen and (min-width: 1400px) {
  //   .fz-#{$i} {
  //     font-size: #{($i + 2)}px !important;
  //   }
  // }
  // @media only screen and (min-width: $xxl) {
  //   .fz-#{$i} {
  //     font-size: #{($i + 4)}px !important;
  //   }
  // }
}

//some class often used

//position
.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute;
}

//display
.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

//justify-content
.jc-center {
  justify-content: center;
}

.jc-space-btwn {
  justify-content:space-between;
}

.jc-space-ard {
  justify-content: space-around;
}

.jc-start {
  justify-content: start;
}

.jc-end {
  justify-content: end;
}

//item
.item-center {
  align-items: center;
}

//text
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

//block
.block-center {
  display: block !important;
  margin: 0 auto;
}

//flex
.flex-column {
  flex-direction: column !important;
}

.flex-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

//full width/height
.full-width {
  width: 100% !important;
}
.full-height {
  height: 100%;
}

//color
.main-color-text {
  color: #000000;
}

.sub-color-text {
  color: #cecece !important;
}

//event
.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}
.button-group {
  gap: 50px;
}

.ant-btn-default {
  background: $primary;
  color: #ffffff;
}

.ant-btn-default:not(:disabled):hover {
  background-color: $primary;
  color: #ffffff;
  border-color: $primary;
}

.ant-btn-primary {
  background: $primary;
  color: #ffffff;
  box-shadow: none;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: $primary;
  color: #ffffff;
  border-color: $primary;
}

.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  color: #58397c;
  font-size: 16px;
  font-weight: 600;
}

.ant-modal .ant-modal-content {
  border: solid #58397c 3px;
  background-color: #e0e0e7;
  border-radius: 0;
  padding: 30px 20px;
   .ant-form-item-control{
    .ant-select-clear{
      margin-right:30px;
    }
   }
}

.ant-modal .ant-modal-footer {
  text-align: center;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 30px;
  .ant-btn {
    min-width: 100px;
    min-height: 40px;
    border-radius: 0;
  }
}

.ant-modal-confirm .ant-modal-confirm-body {
  color: $primary;
  justify-content: center;
}

.ant-modal-confirm .ant-modal-confirm-body > .anticon {
  display: none;
}

.ant-typography {
  color: $primary;
  font-size: 16px;
  font-weight: 700;
}

.ant-input-group .ant-input-group-addon {
  border: none;
  background-color: $white;
  color: $primary;
  font-size: 16px;
  font-weight: 600;
}

.loading-wrapper {
  height: 600px;
  .ant-spin {
    color: #58397c;
  }
}

.ant-table-tbody > tr:hover > td {
  background-color: transparent !important;
}

.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-error {
  border-color: #d9d9d9;
}

.ant-upload-wrapper .ant-upload-list {
  margin-left:5%;
  margin-right: 35%;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  font-size: 20px;
  color: $primary;
}

.modal-timeout {
  font-size: 16px;
  font-weight: 600;
  color: $primary;

  .ant-btn-default {
    display: none;
  }
  .anticon svg {
    display: none;
  }
}
.icon{
  font-size: 14px !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
  margin:0px;
  left: 0;
  right: 0;
}
.border-header-user{
  border: 1px solid #58397c;
}
.color-page{
background-color: #EEEBF2;
}
.pagination{
  text-align: right;
  margin-top: 16px;
  margin-bottom: 16px;
}
.pointer {
  cursor: pointer;
}
.modal-add{
  width: 80% !important;
  top: 110px;
  .ant-modal-content{
    padding: 0;
    border: none;
    .ant-modal-close{
      color: #000000;
      .anticon svg{
        padding: 5px;
        border: 1px solid #bbbbbb;
        border-radius: 5px;
      }
      &.ant-modal-close:hover{
        background-color: white;
      }
    }
  }
  .ant-modal-header{
    border: 1px solid #58397c;
    height: 60px;
    margin: 0;
    display: flex;
    align-items: center;
    .ant-modal-title{
      font-size: 24px;
      padding: 1px 20px;
    }
  }
  .ant-modal-body{
    height: 100% !important;
  }
}
.modal-singer .ant-card-body{
  height:481px !important;
}
.btn-allowclear{
  .ant-select-selection-item {
    position: relative;
  }
  
  /* Style the "Clear" button icon */
  .ant-select-selection-item-remove {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: red; /* Change the color as desired */
    cursor: pointer;
  }
  .ant-select-disabled .ant-select-selection-item-remove {
    display: none;
  }
}
.form-group {
   line-height: 48px;
}
.disabled-row {
  pointer-events: none;
  cursor: disabled;
}
.ant-table-expanded-row > .ant-table-cell{
  padding: 0 !important;
  border-top: none;
}
.word-break{
   word-break: break-all;
}
.fz-8{
  font-size: 8px;
}
.label-model{
  font-size: 16px;
  font-weight: 700;
  color: #58397c;
}
.pagination-wrapper .ant-pagination{
  .ant-pagination-options .ant-select-selector  {
    cursor: pointer;
  }
}