.table-account {
    height: 260px;
    text-align: left;
    position: relative;
    border-collapse: collapse; 
    background-color: #f6f6f6;
    th:first-child{
    border-right: 0px;
    }
    
    th:nth-child(2){
      border-left: 0px;
    }
    td {
      border: 1px solid #999;
      font-size: 20px;
      padding: 15px;
    }
    .label{
      text-align: center;
    }
    th {
      border: 1px solid #58397c;
      color: black;
      border-radius: 0;
      position: sticky;
      top: 0;
      padding: 10px;
      font-size: 24px;
    }
    tr{
      height: 50px;
    }
    
  }
.head-table{
  width: 15%;
} 
.btn-edit-account{
  text-align: right;
  line-height: 20px;
}
.edit-account-form{
  .ant-form{
     padding: 10px 24%;
  }
  .btn-submit{
     padding-top: 100px;
     .ant-btn{
         width: 140px;
         height: 40px;
     }
     .btn-delete{
       float: right;
     }
     text-align: center;
  }
 }


  
  