.tab-wrapper {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
    justify-content: center;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0;
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: none;
    background: #291c3d;
    font-weight: 600;
    color: #ffffff;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    color: #ffffff;
    background: #523574;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
    font-weight: 600;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0;
  }
}
