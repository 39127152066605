.layout-sider {
  position: fixed !important;
  overflow: auto;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  overflow-x: hidden;
  margin-right: 200px !important;
  margin-top: 64px;

  .logo {
    height: 32px;
    margin: 16px !important;
  }
  .btn-collapse {
    width: 100%;
    height: 50px;
  .icon-close{
   margin-left: 90%;
    }
  }
.ant-menu-item{
  left: 0;
  right: 0;
  width:100%;
  height: 50px;
  align-items: center;
  .ant-menu-title-content{
    color: black;
    width: 100%;
    font-size: 18px;
  }
  .anticon {
    color: black;
  }
  .ant-menu-item-icon{
    height: 32px;
    width: 32px;
  }
}
.ant-menu-vertical .ant-menu-item{
margin-inline: 0;
}
.ant-menu-inline .ant-menu-item{
  margin-inline: 0;
  top: 0;
  bottom: 0;
  align-items: center;
}
  .ant-menu-inline {
    border-inline-end: none !important;
    color: #948e8e;
    .ant-menu-item-selected {
      color: #EBEBEB;
      background-color:#EBEBEB ;

    }
  }
  .ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):active {
    color: #EBEBEB; 
    background-color:#EBEBEB ;
  }
  .ant-menu-light .ant-menu-item-selected{
    color: #EBEBEB; 
    background-color:#EBEBEB ;
  }
}

.ant-layout-sider-collapsed{
  position: relative;
  .btn-collapse {
    left: 0;
  }
  .ant-menu-item{
    padding-inline: calc(50% - 5px - 11px);
    .ant-menu-item-icon{
      height: 32px;
      width: 32px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    
    }
  }
}
.layout-wraper-content {
  min-height: calc(100vh - 65px) !important;
  background-color: #f7f7f7;
  word-break: break-all;
  transition: all 0.2s;
  &.collapsed {
    margin-left: 70px;
  }
  &.uncollapsed {
    margin-left: 250px;
  }
}
.ant-layout-sider-children {
  background: white;
}

