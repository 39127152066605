.add-company-form{
    .ant-form{
       padding: 10px 24%;
    }
    .btn-submit{
       padding-top: 300px;
       .ant-btn{
           width: 140px;
           height: 40px;
       }
       .btn-delete{
         float: right;
       }
       text-align: center;
    }
   }
.company-list-wrapper{
  .table-custom{
    .ant-table-thead>tr{
      .ant-table-row-expand-icon-cell:first-child{
         padding:0 10px 0 10px;
         }
       }
   }   
}
.layout-list-wrapper{
   .table-custom{
     .ant-table-thead>tr{
       .ant-table-row-expand-icon-cell:first-child{
          padding:0 5px 0 5px;
          }
        }
    }   
 }
