.add-object-form{
 .ant-form{
    padding: 10px 0;
 }
 .btn-submit{
    padding-top: 200px;
    .ant-btn{
        width: 140px;
        height: 40px;
    }
    .btn-delete{
      float: right;
    }
    text-align: center;
 }
}
.object-form {
    .ant-btn-default {
      background-color: $primary;
      color: #ffffff;
    }
    .ant-form-item
      .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      display: none;
    }
    .ant-form-item .ant-form-item-label > label {
      font-size: 16px;
      font-weight: 700;
      color: $primary;
    }
    .form-content {
      background-color: $white;
      padding: 40px 20px;
    }
  
    .text-right {
      text-align: right;
    }
  }
  .object-list-wrapper{
    .table-custom{
      .ant-table-tbody>tr>td:first-child{
        padding:0 16px 0 16px;
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .model {
       width: 55% !important;
    }
  }