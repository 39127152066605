.login {
  height: 90vh;
  .logo-wrapper {
    height: 25%;
    .logo-dafuku {
      svg {
        width: 20%;
      }
    }
    .logo-faview {
      svg {
        width: 24%;
      }
    }
  }

  .login-form-wrapper {
    // height: 30%;
    height: 50%;
    .btn-login-ms {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: 1px solid black;
      border-radius: 8px;
      width: 300px;
      padding: 8px;
      cursor: pointer;
      .icon-ms {
        display: flex;
        padding-right: 8px;
      }
      .text {
        font-size: 14px;
      }
    }
    .login-form {
      width: 15%;
      max-width: 500px;
      margin: auto;
      .ant-form-item-label {
        text-align: center;
      }
      .ant-form-item-label > label {
        font-size: large;
        color: #70528c;
        font-weight: 500;
      }
      .ant-input {
        border-radius: 0;
      }
      .ant-input-affix-wrapper {
        border-radius: 0;
      }
      .ant-form-item
        .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before {
        display: none;
      }
    }
  }

  .input-email {
    height: 52px;
    border: 1px solid #5b387b;
  }

  .list-message {
    margin: 0;
    padding: 27px 0 51px;
  }

  .button-submit {
    width: 100%;
    height: 52px;
    font-size: 21px;
    font-weight: 500;
    border-radius: 0;
    background: #70528c;
  }

  .cache-wrapper {
    height: 10%;
    .button-cache {
      width: 70px;
      height: 100%;
      max-height: 50px;
      border-radius: 0;
      background: #70528c;
    }
  }

  .ant-btn-primary:not(:disabled):hover {
    background-color: #70528c;
  }
}
