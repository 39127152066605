.license{
    height: 800px;
    background-color: white;
   
    .text1{
     word-break: break-word;
     width: 50%;
    }
    .text2{
        word-break: break-word;
        width: 55%;
    }
    .text3{
        word-break: break-word;
        width: 70%;
    }
}