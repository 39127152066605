$xxl: 1600px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 480px;

// color
$primary: #58397c;
$white: #fff;
